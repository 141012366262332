import { gql, InMemoryCache, makeVar } from '@apollo/client'

import { AddExistingProps } from '../components/native-package/List/Adder'
import { AddMenuItemSelectorProps } from '../components/native-package/ViewMenu/ViewMenu'

export const DetailModalVar = makeVar({
  isOpen: false,
  blockId: null,
})

export const AddBlockVar = makeVar<AddExistingProps | null>(null)

export const AddMenuItemSelectorVar = makeVar<AddMenuItemSelectorProps | null>(
  null
)

export const ViewPaths = makeVar(null)

export const openFilterPopovers = makeVar([])

export const cacheId = (object) =>
  object.uid ? `${object.__typename}:${object.uid}` : object.id || null

export const cache = new InMemoryCache({
  dataIdFromObject: cacheId,
  typePolicies: {
    Block: {
      fields: {
        clientId: {
          read(exisiting) {
            return exisiting ?? null
          },
        },
      },
    },
    DateBlock: {
      fields: {
        clientId: {
          read(exisiting) {
            return exisiting ?? null
          },
        },
      },
    },
    Query: {
      fields: {
        blocks: {
          merge(existing, incoming, { readField }) {
            const recentlyDeletedBlockIds = window?.recentlyDeletedBlocks || []

            return {
              ...incoming,
              resultBlocks: incoming.resultBlocks.filter((b: any) => {
                const id = readField('uid', b)

                return !recentlyDeletedBlockIds.includes(id)
              }),
            }
          },
        },
        blocksByRelation: {
          read(existing, { args, toReference, readField, cache, ...rest }) {
            if (existing) {
              return existing
            }

            if (
              // Could remove this condition, but without accepting partial result in the list,
              // it isn’t very useful there
              args.relations.length === 1 &&
              !args.relations[0].isAnyAncestor
            ) {
              const cachedRelations = readField('relations', {
                __ref: cacheId({
                  __typename: 'Block',
                  uid: args.relations[0].id,
                }),
              })

              const relatedBlocks = cachedRelations.edges
                .filter(
                  (r) => r.name === args.relations[0].name && !r.isReverse
                )
                .map((r) => r.block)

              return relatedBlocks
            }
            return existing
          },
          merge(existing, incoming, { readField, mergeObjects, ...rest }) {
            const recentlyDeletedBlockIds = window?.recentlyDeletedBlocks || []

            return incoming.filter((b: any) => {
              const id = readField('uid', b)

              return !recentlyDeletedBlockIds.includes(id)
            })
          },
          keyArgs: ['relations'],
        },
        blocksByIds: {
          read(_, { args }) {
            return args.ids.map((id) => ({
              __ref: cacheId({
                __typename: 'Block',
                uid: id,
              }),
            }))
          },
        },
        modal: {
          read() {
            return DetailModalVar()
          },
        },
        viewPaths: {
          read() {
            return ViewPaths()
          },
        },
      },
    },
  },
})

export const blockById = (client) => (id) =>
  client.readFragment({
    id: cacheId({
      __typename: 'Block',
      uid: id,
    }),
    fragment: gql`
      fragment SomeBlock on Block {
        uid
        richText
        plainText
      }
    `,
  })

export const dateBlockById = (client) => (id) =>
  client.readFragment({
    id: cacheId({
      __typename: 'DateBlock',
      uid: id,
    }),
    fragment: gql`
      fragment Date on DateBlock {
        uid
        date
      }
    `,
  })
